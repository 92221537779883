.App {
  font-family: sans-serif;
  text-align: center;
}

.slider-container-component {
  position: relative;
}
.slider-container-component .slider {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 6px 0;
}
.slider-container-component .slider__track {
  position: relative;
  height: 12px;
  cursor: pointer;
  background: linear-gradient(to right, #f660ff, #65bafa, #8fec51);
  border-radius: 6px;
  margin: 6px 12px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}
.slider-container-component .slider__handle {
  user-select: none;
  border: 1px solid #bcbcbc;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.15);
  margin-top: -6px;
  display: inline-block;
  position: absolute;
  left: 0;
  z-index: 1;
  transition: transform 0.2s ease;
}
.slider-container-component .slider__handle.react-draggable-dragging {
  transition: none;
}
.slider-container-component .slider__ticks {
  position: relative;
  width: 100%;
  margin-top: 1px;
  line-height: 0;
  cursor: pointer;
  pointer-events: none;
}

.slider-container-component .slider__markers {
  position: absolute;
  width: 100%;
  margin-top: 1px;
  line-height: 0;
  cursor: pointer;
  pointer-events: none;
}

.one_tick {
  position: absolute;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 10px;
  display: inline-block;
  margin-left: -1px;
  margin-top: 1px;
  pointer-events: none;
}

.slider-container-component .slider__markers {
  transform: translateY(-24px);
}
.slider-container-component .slider__tick {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 10px;
  display: inline-block;
  position: relative;
  margin-left: -1px;
  pointer-events: none;
}
.slider-container-component .slider__tick--marker {
  margin-left: calc(49%);
  margin-top: 8px;
}
.slider-container-component .slider__marker,
.slider-container-component .marker {
  display: inline-block;
  position: absolute;
  transform: translateX(50%);
  height: 10px;
}
.slider-container-component .marker__label {
  margin: 0;
  line-height: 1;
}
.slider-container-component.dragging .popover-container > .popover {
  transition: none;
}
.slider-container-component .popover-container {
  margin-top: 12px;
  position: relative;
}
.slider-container-component .popover-container .popover {
  transition: left 0.2s ease;
  background: #fff;
  border: 1px solid #dcdcdc;
  box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.15);
  width: 150px;
  left: -61px;
  transform: translateX(calc(-36%));
  height: 50px;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #464646;
  text-align: center;
  border-radius: 4px;
  cursor: initial;
  position: absolute;
}
.slider-container-component .popover-container .popover__arrow {
  position: relative;
  background: #ffffff;
  height: 6px;
  width: 12px;
  top: -10px;
  margin-left: -6px;
  left: 50%;
  border: none;
}
.slider-container-component .popover-container .popover__arrow:after,
.slider-container-component .popover-container .popover__arrow:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.slider-container-component .popover-container .popover__arrow:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 5px;
  margin-left: -5px;
  top: initial;
}
.slider-container-component .popover-container .popover__arrow:before {
  border-color: rgba(220, 220, 220, 0);
  border-bottom-color: #dcdcdc;
  border-width: 6px;
  margin-left: -6px;
}

.slider-value {
  background-image: -webkit-linear-gradient(
    left,
    #9a99fc 0%,
    #67b9fa 50%,
    #73cbbf 100%
  );
  background-image: linear-gradient(
    90deg,
    #9a99fc 0%,
    #67b9fa 50%,
    #73cbbf 100%
  );
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  min-width: 90px;
  max-width: 200px;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
  color: #ffffff;
  margin: 0 auto 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
